<template>
    <div class="nibnut">
        <div class="nibnut-app">
            <app-header
                :is-public-page="is_public_page"
                @navigate="set_navigation"
                @bug-report="reporting_bug = true"
            />
            <div
                class="body"
                v-touch:swipe="swiped"
            >
                <app-sidenav
                    :navigating="navigating"
                    :installable="!is_installed && installable"
                    @navigate="set_navigation"
                    @install="maybe_show_installation_prompt"
                />
                <main>
                    <router-view></router-view>
                </main>
            </div>
        </div>

        <bug-reporter
            v-if="reporting_bug"
            :show.sync="reporting_bug"
        />

        <offline v-if="offline" />
        <div v-else-if="bootstrapping" class="full_page_loader">
            <div>
                <app-logo />
                <loader size="lg" />
            </div>
        </div>
        <authentication />

        <modal-dialog
            v-if="!!app_context && !!app_context.settings"
            id="pwa-install-prompt"
            :show.sync="showing_installation_prompt"
        >
            <template v-slot:title>
                <span class="h5">{{ install_prompt_title }}</span>
            </template>

            <div class="text-center">
                <p class="mb-8">{{ translate("You can add the {application_name} application to your home screen, so it's easy to access:", { application_name: app_context.settings.application_name }) }}</p>
                <div v-if="!!install_event" class="btn_install">
                    <base-link
                        href="#"
                        @click.prevent="install"
                    >
                        {{ translate("Install on my device") }}
                    </base-link>
                </div>
                <p v-else v-html="install_prompt_ios_instructions"></p>
                <p class="text-small mt-8">
                    {{ translate("You can install the app at any time through the navigation menu on the left") }}
                </p>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent="showing_installation_prompt = false"
                    >
                        {{ translate("Not now") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <system-message
            v-if="is_safari&&!!system_message.message"
            :message="system_message"
            :countdown="message_countdown"
            @click="dismiss_message"
        />
        <system-message
            v-if="!!system_message.message"
            :message="system_message"
            :countdown="message_countdown"
            @click="dismiss_message"
        />
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { profile_utilities, ui_utilities, string_utilities } from "@/nibnut/mixins"

import AppHeader from "./AppHeader"
import AppSidenav from "./AppSidenav"
import SystemMessage from "./SystemMessage"
import { Authentication, Offline } from "@/nibnut/dialogs"
import { Loader } from "@/custom/components"
import { AppLogo, ModalDialog, BaseLink, DefaultButton } from "@/nibnut/components"

import ios_action_button from "@/assets/img/Navigation_Action_2x.png"

let timer = null
const is_ios = () => {
    if(!window.MSStream) {
        const agent_data = navigator.userAgentData || {}
        const platform = agent_data.platform || navigator.platform
        return !!platform && /iPad|iPhone|iPod/.test(platform)
        /*
        const user_agent = window.navigator.userAgent
        if(/iphone|ipad|ipod/i.test(user_agent)) {
            const start = user_agent.indexOf("OS ")
            return ((start < 0) || (window.Number(user_agent.substr(start + 3, 3).replace("_", ".")) > 10))
        }
        */
    }
    return false
}
const is_ios_installed = () => {
    return ("standalone" in window.navigator) && window.navigator.standalone
}

export default {
    components: {
        AppHeader,
        AppSidenav,
        SystemMessage,
        Offline,
        Authentication,
        AppLogo,
        ModalDialog,
        BaseLink,
        DefaultButton,
        Loader,
        BugReporter: () => import("./nibnut/dialogs/BugReporter")
        // 'my-component': () => import('./my-async-component')
    },
    mixins: [profile_utilities, ui_utilities, string_utilities],
    created () {
        try {
            const agent_data = navigator.userAgentData
            if(agent_data) this.is_safari = !!agent_data.brands.find(brand => brand.brand.match(/safari/i))
            else this.is_safari = navigator.appVersion && navigator.appVersion.match(/Safari/) && !navigator.appVersion.match(/Chrome/)
            if(this.is_safari) {
                setTimeout(() => {
                    document.body.className += " is-safari"
                }, 500)
            }
        } catch (e) {
        }

        document.addEventListener("pwa-updated", this.app_updated, { once: true })
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if(this.app_updating) return
            this.app_updating = true
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName)
                })
            })
            window.location.reload(true)
        })

        window.addEventListener("beforeinstallprompt", this.bootstrap_installation)
        window.addEventListener("online", this.online_status_changed)
        window.addEventListener("offline", this.online_status_changed)
        window.addEventListener("scroll", this.scrolled)

        this.navigating = !this.small_screen && !this.$cookie.get("hide-navigation")
    },
    mounted () {
        this.load()
    },
    beforeRouteLeave () {
        if(this.small_screen) this.set_navigation(false)
    },
    beforeDestroy () {
        window.removeEventListener("beforeinstallprompt", this.bootstrap_installation)
        window.removeEventListener("online", this.online_status_changed)
        window.removeEventListener("offline", this.online_status_changed)
        window.removeEventListener("scroll", this.scrolled, false)
    },
    watch: {
        "system_message.message": "system_message_changed",
        "login_request.panel_id": "show_next_post_login_screen"
    },
    methods: {
        load () {
            this.$store.dispatch("LOAD_PROFILE").catch(() => {
                // ignore any error
            })
        },
        bootstrap_installation (install_event) {
            this.install_event = install_event
        },
        scrolled () {
            this.scrolled_deep = document.documentElement.scrollTop >= window.innerHeight
        },
        set_navigation (navigating = null) {
            if(navigating === null) navigating = !this.navigating
            this.navigating = navigating
            if(this.navigating) this.$cookie.delete("hide-navigation")
            else this.$cookie.set("hide-navigation", true, { expires: "1Y" })
        },
        online_status_changed () {
            this.$store.dispatch("EVALUATE_ONLINE_STATUS")
        },
        show_next_post_login_screen () {
            if(!!this.profile_id && !this.login_request.panel_id) {
                // if(!this.installable_manually && !this.$cookie.get("pwa-introduced")) {
                if(this.installable_manually && !this.$cookie.get("pwa-introduced")) {
                    this.$cookie.set("pwa-introduced", true, { expires: "1Y" })
                    this.showing_installation_prompt = true
                }
            }
        },
        system_message_changed () {
            if(timer) {
                clearInterval(timer)
                timer = null
            }
            if(this.system_message.message) {
                this.message_countdown = this.system_message.dismiss_after || 7000
                const step = 10
                timer = setInterval(() => {
                    this.message_countdown -= step
                    if(this.message_countdown <= 0) {
                        clearInterval(timer)
                        timer = null
                        this.dismiss_message()
                    }
                }, step)
            }
        },
        dismiss_message (event) {
            const system_message = this.system_message
            const system_message_id = system_message ? system_message.message_id : null
            this.$store.dispatch("SYSTEM_MESSAGE", {
                type: "warning",
                message: "",
                dismiss_after: 0,
                message_id: null
            })
            if(!!system_message_id && (system_message_id === "update-app")) {
                this.update_app()
            }
        },
        app_updated (event) {
            this.app_registration = event.detail
            this.$warn(this.translate("A new version of this app is available. Click here to upgrade now."), 30000, "update-app")
        },
        update_app () {
            if(!this.app_registration || !this.app_registration.waiting) return
            this.app_registration.waiting.postMessage({ type: "SKIP_WAITING" })
        },
        maybe_show_installation_prompt () {
            this.set_navigation(false)
            // ** user clicked the button... If we're not on iOS, trigger the browser prompt directly.
            // else show the install instructions dialog
            if(this.installable_manually) this.showing_installation_prompt = true
            else if(this.installable) this.install()
        },
        install () {
            this.showing_installation_prompt = false
            if(!this.install_event) return

            this.install_event.prompt()
            // Wait for the user to respond to the prompt
            this.install_event.userChoice.then(choice => {
                if(choice.outcome === "accepted") {
                    // console.log("User accepted the A2HS prompt")
                } else {
                    // console.log("User dismissed the A2HS prompt")
                }
                this.install_event = null
            })
        },
        swiped (direction) {
            if((direction === "left") || (direction === "right")) this.set_navigation(direction === "right")
        }
    },
    computed: {
        ...mapState(["offline", "bootstrapping", "system_message", "login_request"]),
        installable () {
            return (is_ios() && !is_ios_installed()) || (!!this.install_event && !this.$cookie.get("do-not-install"))
        },
        is_installed () {
            return !!window.matchMedia("(display-mode: standalone)").matches || is_ios_installed()
        },
        installable_manually () {
            return !this.install_event && is_ios() && !is_ios_installed()
        },
        install_prompt_title () {
            if(!this.install_event) return this.translate("Install on my device")
            return this.translate("How to install on my device")
        },
        install_prompt_ios_instructions () {
            const instructions = this.translate("Simply tap the [image] icon and then select the 'Add to Home Screen' option.")
            const matches = instructions.match(/^(.*?)\[([^\]]+?)\](.*?)$/)
            if(matches) {
                return `${matches[1]}<img src="${ios_action_button}" style="height: 1em;" class="mx-3" />${matches[3]}`
            }
            return instructions
        }
    },
    data () {
        return {
            is_safari: false,
            install_event: null,
            showing_installation_prompt: false,
            scrolled_deep: false,
            navigating: true,
            message_countdown: 0,

            reporting_bug: false,

            app_updating: false,
            app_registration: null
        }
    },
    metaInfo () {
        const application_name = this.setting("application_name")
        if(!this.title) {
            return {
                title: application_name
            }
        }
        return {
            title: this.title,
            titleTemplate: `%s | ${application_name}`
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

.nibnut {
    & > .nibnut-app {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        & > div.header {
            position: sticky;
            flex: 0 0 100%;
            top: 0;
            padding: $layout-spacing-lg;
            background-color: $body-bg;
            z-index: $zindex-2;

            img.app-logo {
                height: 1.5rem;
            }
            .avatar {
                margin-top: -0.4rem;
            }
        }
        & > div.body {
            flex: 1 0 auto;
            display: flex;

            $nav-width: 4rem;
            $nav-active-width: 15vw;
            $nav-max-width: 200px;
            & > nav.nibnut-sidenav {
                flex: 1 1 auto;
                position: relative;
                width: $nav-width;
                max-width: $nav-max-width;
                background-color: $bg-color-dark;
                padding-bottom: 2.5rem;
                overflow: hidden;
                transition: width 0.3s ease-in-out;
                z-index: $zindex-2;

                & > .nav {
                    min-height: 100%;
                    margin: 0;

                    & > .nav-item {
                        padding: $layout-spacing-sm $layout-spacing-lg;
                        margin: 0;
                        border-left: 0.25rem solid $bg-color-dark;
                        border-bottom: 1px solid $body-bg;
                        background-color: $bg-color-dark;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;

                        & > a {
                            & > i {
                                font-size: 1rem;
                            }
                            & > span {
                                margin-left: $control-padding-x;
                                display: none;
                            }
                        }

                        &.active {
                            border-left-color: $primary-color;
                            background-color: $body-bg;
                        }
                    }
                }
                & > .nibnut-sidenav-footer {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    width: $nav-width;
                    max-width: $nav-max-width;
                    text-align: center;
                    font-size: 0.5rem;
                    overflow: hidden;

                    img {
                        height: 0.5rem;
                    }
                }

                &.active {
                    width: $nav-active-width;

                    & > .nav {
                        & > .nav-item {
                            text-align: left;

                            & > a {
                                & > span {
                                    display: inline;
                                }
                            }
                        }
                    }
                    & > .nibnut-sidenav-footer {
                        width: $nav-active-width;
                    }
                }
            }
            & > main {
                flex: 1 1 100%;
                padding: $layout-spacing-lg;
            }
        }
    }
    @media (max-width: $size-sm) {
        & > .nibnut-app {
            & > div.body {
                display: block;

                $nav-width: 0;
                $nav-active-width: 100%;
                $nav-max-width: none;
                & > nav.nibnut-sidenav {
                    flex: none;
                    position: fixed;
                    top: $top-nav-height;
                    left: 0;
                    bottom: 0;
                    width: $nav-width;
                    max-width: $nav-max-width;

                    & > .nav {
                        & > .nav-item {
                            text-align: left;
                        }
                    }
                    & > .nibnut-sidenav-footer {
                        width: $nav-width;
                        max-width: $nav-max-width;
                    }

                    &.active {
                        width: $nav-active-width;

                        & > .nibnut-sidenav-footer {
                            width: $nav-active-width;
                        }
                    }
                }
                & > main {
                    flex: none;
                    position: relative;
                    left: 0;
                }
            }
        }
    }

    & > .full_page_loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: $bg-color;
        transition: all 0.3s ease-in-out;
        z-index: $zindex-5;

        & > div {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            & > img {
                max-height: 5rem;
                margin: 0 auto $unit-8  auto;
            }
            & > .loader {
                position: relative;
                display: inline-block;
            }
        }
    }
}
</style>
