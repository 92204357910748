<template>
    <div
        :class="{'active': !!shown}"
        class="modal authentication"
    >
        <dismiss-link
            @click="dismiss"
            class="modal-overlay"
        />
        <div class="modal-container">
            <login
                v-if="current_panel_id==='login'"
                v-model="email"
                :label-less="labelLess"
                :dismissable="dismissable"
                :class="{ 'xyz-out': (current_panel_id !== 'login'), 'xyz-in': (current_panel_id === 'login') }"
                @goto="current_panel_id=$event"
                @dismiss="dismiss"
            />
            <signup
                v-else-if="can_signup && (current_panel_id==='signup')"
                v-model="email"
                :label-less="labelLess"
                :dismissable="dismissable"
                :class="{ 'xyz-out': !can_signup || (current_panel_id !== 'signup'), 'xyz-in': can_signup && (current_panel_id === 'signup') }"
                @goto="current_panel_id=$event"
                @dismiss="dismiss"
            />
            <lost-password
                v-else-if="current_panel_id==='password'"
                v-model="email"
                :label-less="labelLess"
                :dismissable="dismissable"
                :class="{ 'xyz-out': (current_panel_id !== 'password'), 'xyz-in': (current_panel_id === 'password') }"
                @goto="current_panel_id=$event"
                @dismiss="dismiss"
            />
            <reset-password
                v-else-if="current_panel_id==='reset'"
                v-model="email"
                :label-less="labelLess"
                :dismissable="dismissable"
                :class="{ 'xyz-out': (current_panel_id !== 'reset'), 'xyz-in': (current_panel_id === 'reset') }"
                @goto="current_panel_id=$event"
                @dismiss="dismiss"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import Login from "./Login"
import LostPassword from "./LostPassword"
import ResetPassword from "./ResetPassword"
import DismissLink from "@/nibnut/components/ModalDialog/DismissLink"
import Signup from "@/custom/dialogs/Authentication/Signup"

export default {
    mixins: [profile_utilities],
    components: {
        Login,
        Signup,
        LostPassword,
        ResetPassword,
        DismissLink
    },
    watch: {
        profile_id: "maybe_dismiss",
        "app_context.settings.application_name": "maybe_switch_panel",
        "login_request.panel_id": "maybe_switch_panel"
    },
    methods: {
        dismiss () {
            if(this.dismissable) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        maybe_dismiss () {
            if(this.profile_id) this.shown = false
        },
        maybe_switch_panel () {
            if(this.shown && !!this.app_context && !!this.app_context.settings) {
                if(this.login_request.panel_id === true) {
                    if(this.can_signup && !this.is_app_user) this.current_panel_id = "signup"
                    else this.current_panel_id = "login"
                } else this.current_panel_id = this.login_request.panel_id
            }
        }
    },
    computed: {
        ...mapState(["profile_id", "login_request", "app_context"]),
        shown: {
            get () {
                return !this.profile_id && !!this.login_request && !!this.login_request.panel_id
            },
            set (show) {
                if(!show) this.dismiss()
            }
        },
        can_signup () {
            return (!!window.location.search && !!window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)) || (!!this.app_context && !!this.app_context.settings && !!this.app_context.settings.signups)
        },
        dismissable () {
            if(this.$route.meta) return !this.$route.meta.admin_route && !this.$route.meta.login_required
            // eslint-disable-next-line
            return !!process.env.VUE_APP_DEFAULT_PUBLIC_PATH && process.env.VUE_APP_DEFAULT_PUBLIC_PATH.match(/^\//)
        }
    },
    props: {
        labelLess: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            current_panel_id: "",
            email: ""
        }
    }
}
</script>

<style lang="scss">
.modal.authentication {
    & > .modal-container {
        overflow: hidden;

        & > div {
            overflow: auto;
            animation-duration: 0.35s;

            .modal-header {
                .app-logo {
                    max-height: 5rem;
                    margin: 0 auto;
                }
            }
            .modal-body {
                & > .loader-container {
                    text-align: center;

                    & > .loader {
                        margin-top: 3rem;
                        margin-bottom: 3rem;
                    }
                }
            }
            .modal-footer {
                text-align: center;
            }
        }
    }
}
</style>
