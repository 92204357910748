<template>
    <input-group>
        <template v-slot:right_addon :toggle="toggle">
            <slot name="right_addon" :toggle="toggle">
                <default-button
                    @click.prevent="toggle"
                    class="input-group-btn"
                >
                    <app-icon :glyph="glyph" />
                </default-button>
            </slot>
        </template>
        <base-input
            ref="field"
            :id="identifier"
            :type="standardized_type"
            :name="name"
            :value="value"
            :disabled="disabled"
            :placeholder="placeholder"
            :required="required && touched"
            :autocomplete="autocomplete"
            autocapitalize="none"
            @focus="touched = true"
            @touchstart="touchstart"
            @keyup="$emit('keyup', $event)"
            @keydown="keydown"
            @blur="blur"
            @change="changed"
        />
    </input-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import InputGroup from "./InputGroup"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseInput from "./BaseInput"
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    name: "PasswordField",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        InputGroup,
        DefaultButton,
        BaseInput,
        AppIcon
    },
    methods: {
        toggle (event) {
            this.peeking = !this.peeking
            event.target.closest("button").blur()
        },
        changed (event) {
            this.$emit("input", event.target.value, this.name)
        }
    },
    computed: {
        glyph () {
            if(this.peeking) return "eye-slash"
            return "eye"
        },
        standardized_type () {
            if(this.peeking) return "text"
            return "password"
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: "new-password"
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            touched: false,
            peeking: false
        }
    }
}
</script>
